export type PageNumber = number;

export type Meta = {
  current_page: number;
  next_page: number;
  previous_page: number;
  total_pages: number;
  total_count: number;
  per_page: number;
};

export const emptyMeta = {
  current_page: null,
  next_page: null,
  previous_page: null,
  total_pages: null,
  total_count: null,
  per_page: null,
};

export type MetaCursor = {
  current_cursor: number;
  next_cursor: number;
  total_count: number;
  per_page: number;
};
