import { Meta } from '@app/api/pagination';
import { NotebookPost } from '@app/api/resources/notebook/NotebookPost';

import * as actionTypes from '@app/actionTypes';

export const setNotebookPosts = (notebookPosts: NotebookPost[]) => ({
  type: actionTypes.SET_NOTEBOOK_POSTS,
  payload: {
    notebookPosts,
  },
});

export const addNotebookPosts = (notebookPosts: NotebookPost[]) => ({
  type: actionTypes.ADD_NOTEBOOK_POSTS,
  payload: {
    notebookPosts,
  },
});

export const addPageOfNotebookPostsForHome = (
  notebookPostIds: string[],
  pageNum: number,
  pageMeta: Meta,
) => ({
  type: actionTypes.ADD_PAGE_OF_NOTEBOOK_POSTS_FOR_HOME,
  payload: {
    notebookPostIds,
    pageNum,
    pageMeta,
  },
});

export const addPageOfNotebookPostsForAllPosts = (
  notebookPostIds: string[],
  pageNum: number,
  pageMeta: Meta,
) => ({
  type: actionTypes.ADD_PAGE_OF_NOTEBOOK_POSTS_FOR_ALL_POSTS,
  payload: {
    notebookPostIds,
    pageNum,
    pageMeta,
  },
});

export const addPageOfNotebookPostsForAuthor = (
  authorId: number,
  notebookPostIds: string[],
  pageNum: number,
  pageMeta: Meta,
) => ({
  type: actionTypes.ADD_PAGE_OF_NOTEBOOK_POSTS_FOR_AUTHOR,
  payload: {
    authorId,
    notebookPostIds,
    pageNum,
    pageMeta,
  },
});

export const addPageOfNotebookPostsForTag = (
  tagSlug: string,
  notebookPostIds: string[],
  pageNum: number,
  pageMeta: Meta,
) => ({
  type: actionTypes.ADD_PAGE_OF_NOTEBOOK_POSTS_FOR_TAG,
  payload: {
    tagSlug,
    notebookPostIds,
    pageNum,
    pageMeta,
  },
});
