import styled from '@emotion/styled';

import { NotebookPost } from '@app/api/resources/notebook/NotebookPost';

import { ColorKey } from '@app/themes/mubi-theme';

import {
  CommentFilledIcon,
  CommentIcon,
} from '@app/components/icons/CommonIcons';

type SmallOrLarge = 'small' | 'large';

type NotebookCommentCountProps = {
  post: NotebookPost;
  iconFilled?: boolean;
  size?: SmallOrLarge;
  color?: ColorKey;
};

const NotebookCommentCount = ({
  post,
  size = 'large',
  iconFilled = false,
  color = 'midGray',
}: NotebookCommentCountProps) => {
  const commentCount = post?.comment_count ?? 0;

  return (
    <Container color={color}>
      <CommentIconContainer iconWidth={size}>
        {iconFilled ? (
          <CommentFilledIcon width="100%" color="currentColor" />
        ) : (
          <CommentIcon width="100%" />
        )}
      </CommentIconContainer>
      <CommentCountNumContainer fontSize={size}>
        {commentCount}
      </CommentCountNumContainer>
    </Container>
  );
};

const Container = styled.div<{ color: ColorKey }>`
  display: flex;
  align-items: center;
  font-family: ${props => props.theme.font.body};
  color: ${props => props.theme.color[props.color]};
`;

const CommentIconContainer = styled.div<{ iconWidth: SmallOrLarge }>`
  display: flex;
  align-items: center;
  margin-right: 4px;
  margin-top: ${props => (props.iconWidth === 'large' ? '' : '3px')};
  width: ${props => (props.iconWidth === 'large' ? '20px' : '12px')};

  @media (min-width: ${props => props.theme.mq.tablet}) {
    width: ${props => (props.iconWidth === 'large' ? '22px' : '15px')};
  }
`;

const CommentCountNumContainer = styled.div<{ fontSize: SmallOrLarge }>`
  font-size: ${props => (props.fontSize === 'large' ? '14px' : '12px')};
`;

export default NotebookCommentCount;
