import { Fragment } from 'react';
import styled from '@emotion/styled';

import { NotebookAuthor } from '@app/api/resources/notebook/NotebookAuthor';

import { stripDomainFromUrl } from '@app/services/routeHelpers';

import Link from '@app/components/Link';

type AuthorLinkProps = {
  fontColor?: string;
  fontFamily?: 'brand' | 'body';
  fontSize?: string;
};

type NotebookAuthorLinksProps = AuthorLinkProps & {
  authors: NotebookAuthor[];
};

const NotebookAuthorLinks = ({
  authors,
  fontColor = 'black',
  fontFamily = 'body',
  fontSize = '12px',
}: NotebookAuthorLinksProps) => {
  const isLastAuthor = (currentIndex: number) =>
    currentIndex === authors.length - 1;

  return (
    <span>
      {authors.map((author, i) => (
        <Fragment key={author.id}>
          <AuthorLink
            href={stripDomainFromUrl(author.canonical_url)}
            fontFamily={fontFamily}
            fontColor={fontColor}
            fontSize={fontSize}
          >
            {author.name}
          </AuthorLink>

          {!isLastAuthor(i) && (
            <AuthorComma
              fontFamily={fontFamily}
              fontColor={fontColor}
              fontSize={fontSize}
            >
              ,{' '}
            </AuthorComma>
          )}
        </Fragment>
      ))}
    </span>
  );
};

const AuthorLink = styled(Link)<AuthorLinkProps>`
  text-decoration: none;
  color: ${props => props.theme.color[props.fontColor]};
  font-family: ${props => props.theme.font[props.fontFamily]};
  font-weight: bold;
  font-size: ${props => props.fontSize};

  &:visited {
    color: ${props => props.theme.color[props.fontColor]};
  }
  &:hover {
    color: ${props => props.theme.color[props.fontColor]};
  }
`;

const AuthorComma = styled.span<AuthorLinkProps>`
  color: ${props => props.theme.color[props.fontColor]};
  font-family: ${props => props.theme.font[props.fontFamily]};
  font-weight: bold;
  font-size: ${props => props.fontSize};
`;

export default NotebookAuthorLinks;
